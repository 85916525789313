import Navbar from '../components/Navbar';

function About() {
  return (
    <div className='container'>
      <img src="aboutimage.jpg" alt="Hero" className='rsvp-hero-image' />
      <Navbar />
        <p>
            From a casual grocery run to an annual celebration, the journey of the
            "Brent Bought A Big Damn Turkey" party is nothing short of legendary.
        </p>
        <p>
            It was a regular mid-November grocery trip for me. The air was filled with festive 
            anticipation, with Thanksgiving goodies laid out in abundance. In the midst of the 
            frozen turkeys, one colossal bird stood out, dwarfing its counterparts. 
            It wasn't just a turkey; it felt like an embodiment of me in this world — a standout 
            in the realm of the average. Naturally, I was drawn to it and ended up purchasing the 
            impressive 26-pound turkey.
        </p>
        <p>
            Once home, a realization dawned: how does one tackle such a turkey? This bird was 
            beyond the scope of the typical roasting guide, which stopped at 25 pounds. And it 
            was definitely not a feast meant for a quiet dinner for two.
        </p>
        <p>
            The solution? A grand celebration! We decided to transform this dilemma into a potluck 
            party, inviting friends to bring their favorite dishes while we took charge of the turkey 
            centerpiece. After roasting for nearly five hours (yes, it was THAT big), the turkey 
            was ready.
        </p>
        <p>
            As the festivities unraveled, the curious guests wanted to know the reason behind 
            this impromptu feast. The tale of Brent's bond with the gigantic bird made its 
            rounds, and by the end of the night, it was christened the "Brent Bought A Big 
            Damn Turkey" party.
        </p>
        <p>
            The fun-filled evening left an indelible mark on everyone. So much so that, to 
            my surprise, it became a topic of conversation the following year, nudging me to 
            host the second annual. And thus, a tradition was born.
        </p>
        <p>
            Over the years, the party has seen highs and lows. Even the pandemic tried to 
            overshadow our spirit, but the tradition was resilient. It persisted, evolving 
            into a cherished memory for many.
        </p>
        <p>
            And so, the tradition lives on...
        </p>
        <p>
            #BBABDT
        </p>
    </div>
  );
}

export default About;
