import Navbar from '../components/Navbar';

function Home() {
  return (
    <div className="container">
      <img src="claraingrass.png" alt="Hero"  className="hero-image"  />
      <Navbar />
      <div className='bbabdt_year'><span className='bbabdt_year_color'>22nd</span> Annual</div>
      <div className='bbabdt_home'>Brent Bought A Big Damn Turkey</div>
      
      <div className='bbabdt_theme'>I went and did it again</div>
      <div className='bbabdt_when'>7PM Saturday, December 14th 2024</div>
    </div>
  );
}

export default Home;
