import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Navbar from '../components/Navbar';

function RSVP() {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [code, setCode] = useState("");
  const [hotdish, sethotdish] = useState("");
  const [attendees, setAttendees] = useState(0);  
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const navigate = useNavigate();
  //... other state variables ...

  const handleSubmit = () => {
    if (!name) {
        setError("Please enter your name");
        return;
    }
    if (!email.match(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i)) {
        setError("Invalid email address");
        return;
    }
 /*   if (!code) {
        setError("Please enter your code");
        return;
    }*/
     // Make a POST request to your API endpoint
  fetch('https://acr632a3lh.execute-api.us-east-1.amazonaws.com/bbabdt21_rsvp', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
        email: email,
        name: name,
       // code: code,
        hotdish: hotdish,
        attendees: attendees
      }),
  })
    .then(response => {
        return response.json().then(result => {
            return {
              status: response.status,
              result
            };
          });
    })
    .then(data => {
        if (data.status === 200) {
            setName("");
            setEmail("");
           // setCode("");
            sethotdish("");
            setAttendees(0);
            navigate('/thankyou'); 
        }
        else {
          setError(data.result.message);
        }
      })
    .catch(error => {
      alert("Failed to submit RSVP");
      console.error(error);
    });
    // Further validation and submission logic here
  };

  return (
    <div className='container'>
      <img src="partyturkey.jpg" alt="Hero" className='rsvp-hero-image' />
      <Navbar />      
        <div className='rsvp_title'>Time To Party</div>
        {/*<div className='rsvp_title'>Closed For 2023</div>*/}
        {error && <div style={{ color: 'red' }}>{error}</div>}  {/* Error message displayed in red */}
        
        <div className='inputdiv'>
            <label className='rsvplabel'>Name:</label>
            <input className='rsvpinput' placeholder="Name" value={name} onChange={e => setName(e.target.value)} />
        </div>
        <div className='inputdiv'> 
            <label className='rsvplabel'>Email:</label>
            <input className='rsvpinput' placeholder="Email" value={email} onChange={e => setEmail(e.target.value)} />
            </div>
        {/* 
        <div className='inputdiv'>
            <label className='rsvplabel'>Code:</label>
            <input className='rsvpinput' placeholder="Code" value={code} onChange={e => setCode(e.target.value)} />
        </div>
        */}
        <div className='inputdiv'>
            <label className='rsvplabel'>Number of Attendees:</label>
            
            <select value={attendees} onChange={e => setAttendees(e.target.value)}>
                <option value="0">0</option>
                <option value="1">1</option> 
                <option value="2">2</option> 
                <option value="3">3</option> 
                <option value="4">4</option> 
                <option value="5">5</option>
            </select>
        </div>
        <div className='inputdiv'>
            <label className='rsvplabel'>
            Favorite Dish & More:</label>
            <textarea value={hotdish} onChange={e => sethotdish(e.target.value)} />
        </div>
        
        <button className='rsvpbutton' onClick={() => { handleSubmit('yes'); }}>
            Submit RSVP
        </button>
      
    </div>
  );
}

export default RSVP;
